div#main-screen>div#map {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

/* ====================[layer controller]==================== */
div#layer_controller {
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0px;
    margin-right: 10px;
    margin-top: 44px;
}

div#layer_controller>div#frame>div#leave_mouse {
    width: 44px;
    height: 44px;
}

div#layer_controller>div#frame>section#on_mouse {
    display: none;
}

div#layer_controller>div#frame>div#separator {
    height: 0;
    margin: 5px -10px 5px -6px;
}