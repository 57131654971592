/* ====================[layer controller]==================== */
div#layer_controller:hover>div#frame>div#leave_mouse {
    display: none;
}

div#layer_controller:hover>div#frame>section#on_mouse {
    display: block;
    padding: 6px 10px 6px 6px;
}

div#layer_controller:hover>div#frame>section#on_mouse>label>div>span {
    font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}