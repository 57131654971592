





















































































































































































.v-card {
  background: rgba(255, 255, 255, 0.8) !important;
  border-radius: 4px;
}
