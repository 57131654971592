

























































































































div#dropzone {
  width: 100%;
  height: 256px;
  background-color: gainsboro;
  border-color: black;
  border-width: 10px;
}
